import {
  toImageProps,
  toLinkProps,
  PartnersWidget,
  EPartnersWidgetTabsIconPosition,
  TPartnersWidgetCardData,
} from '@front/shared/ds';
import { useRouter } from 'next/router';
import React from 'react';

import { useGetPartnersWidgetProductsQuery } from '../../../features/partnersWidget';

import type { Section, PartnersWidgetProduct } from '@shared/master-types';

export type TPartnersWidgetConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'partners-widget-custom-tabs' }
>;

export const PartnersWidgetCustomTabsConnected: React.FC<
  TPartnersWidgetConnectedProps
> = props => {
  const {
    allTabLabel,
    moreLabel,
    tabsStickyThreshold,
    visibleThreshold,
    showTabs,
    showTabsIcon,
    tabsIconPosition,
    tabs,
  } = props;
  const router = useRouter();
  const currentLocale = String(router.query.locale || '');
  const isString = (value: unknown): value is string =>
    typeof value === 'string';
  const ids = tabs
    .map(tab =>
      Array.isArray(tab.products) ? tab.products.filter(isString) : [],
    )
    .reduce((acc, productIds) => acc.concat(productIds), []);

  const partnerWidgetProductsQuery: Parameters<
    typeof useGetPartnersWidgetProductsQuery
  >[0] = {
    locale: currentLocale,
    draft: false,
    page: 1,
    limit: ids.length,
    where: {
      id: { in: ids },
    },
  };

  const { data } = useGetPartnersWidgetProductsQuery(
    partnerWidgetProductsQuery,
    { skip: !ids.length },
  );

  if (!data?.docs?.length) {
    return null;
  }

  const productsMap: Record<string, PartnersWidgetProduct> = data.docs.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: cur }),
    {},
  );

  const preparedTabs = tabs.map(({ title, products, icon }) => {
    const cards: TPartnersWidgetCardData[] = [];
    products.forEach(productId => {
      if (typeof productId === 'string') {
        const p = productsMap[productId];
        if (
          p &&
          typeof p.type !== 'string' &&
          typeof p.type.icon !== 'string'
        ) {
          cards.push({
            imageProps: p.image ? toImageProps(p.image) : null,
            iconProps: p.type.icon ? toImageProps(p.type.icon) : null,
            linkProps: p.link[0] ? toLinkProps('', p.link[0]) : null,
            description: p.type.title,
          });
        }
      }
    });

    return {
      text: title,
      cards: cards,
      iconProps: icon && showTabsIcon ? toImageProps(icon) : null,
    };
  });

  return (
    <PartnersWidget
      tabs={preparedTabs}
      showTabs={showTabs}
      tabsIconPosition={tabsIconPosition as EPartnersWidgetTabsIconPosition}
      allTabLabel={allTabLabel}
      moreLabel={moreLabel}
      tabsStickyThreshold={tabsStickyThreshold}
      visibleThreshold={visibleThreshold}
    />
  );
};

export default PartnersWidgetCustomTabsConnected;
